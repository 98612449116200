<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';

const { layoutConfig } = useLayout();

const logoUrl = computed(() => {
    return `/demo/images/logo/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.png`;
});
const syLogoUrl = computed(() => {
  return `/demo/images/logo/SYMON3.png`;
});
</script>

<template>
    <div class="layout-footer">
<!--        <img :src="logoUrl" alt="Logo" height="20" class="mr-2" />-->
        <img :src="syLogoUrl" alt="Logo" height="20" class="font-medium ml-2" />
<!--        by
        <span class="font-medium ml-2">PrimeVue</span>-->
    </div>
</template>
<style lang="scss" scoped></style>
