// axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({

    baseURL: import.meta.env.VITE_API_BASE_URL,

    //baseURL: 'http://localhost:8080', // 개발 환경에서 사용한 주소
    //baseURL: 'http://localhost:8888', // 개발 환경에서 사용한 주소
    //baseURL: 'http://192.168.123.154:8888', // 개발 환경에서 사용한 주소
    //baseURL: 'http://13.209.138.27:8888', // 테스트 서버 도메인
    //baseURL: 'https://symon.co.kr', // 실서버 도메인
    // 기타 설정...
});

export default axiosInstance;